import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Button } from '@chakra-ui/react'

import Layout from '../components/layout'

import {
  Article,
  Section,
  FeatureItem,
  FeatureList,
  Image,
  RowContent,
  ColImage,
  P,
  ColInfo,
} from '../ui'
import FormContact from '../components/formContact'

function SpaPage({ data }) {
  const { t } = useTranslation()
  const [selectedTour, setSelectedTour] = useState('')

  const onSubmit = async (values, setSuccess) => {
    const body = values
    body.to = 'info@hotelmayansecret.com'

    const res = await fetch(`https://form-server.vercel.app/api`, {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: JSON.stringify(body),
    })

    if (res.ok) setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout title={t('spa:title')}>
      <Article>
        <Section title={t('spa:title')} titleAlign="center" subtitle={t('spa:desc')}>
          <RowContent>
            <ColImage
              image={<Image fixed={data.featured.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo title={t('spa:intro.title')} backgroundColor="gray.100">
              <P>{t('spa:intro.desc1')}</P>
              <P>{t('spa:intro.desc2')}</P>
            </ColInfo>
          </RowContent>
        </Section>
        <Section title={t('spa:experiences.title')} titleAlign="center" spacing={10}>
          <FeatureList columns={{ base: 1, md: 2, lg: 3 }}>
            {t('spa:experiences.items', { returnObjects: true }).map((spa) => (
              <FeatureItem
                key={v4()}
                image={<Image fixed={data[spa.id].childImageSharp.fixed} layout="background" />}
                title={spa.title}
                desc={spa.desc}
              />
            ))}
          </FeatureList>
        </Section>

        {/* <Section
          id="contact"
          title={t('spa:contact.title')}
          subtitle={t('spa:contact.desc')}
          titleAlign="center"
          backgroundColor="gray.100"
        >
          <Box
            backgroundColor="white"
            boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
            padding={{ base: 6, md: 8 }}
            borderRadius="15px"
            maxWidth={{ base: '100%', md: '720px' }}
            marginX={{ base: 0, md: 'auto' }}
            width="100%"
          >
            <FormContact
              hideOnSuccess
              onSubmit={(values, setSuccess) => onSubmit(values, setSuccess)}
              name={{
                label: t('contact:form.inputs.name.label'),
                required: t('contact:form.inputs.name.required'),
                invalid: t('contact:form.inputs.name.invalid'),
                placeholder: t('contact:form.inputs.name.placeholder'),
              }}
              email={{
                required: t('contact:form.inputs.email.required'),
                invalid: t('contact:form.inputs.email.invalid'),
              }}
              message={{
                defaultValue: selectedTour,
                label: t('contact:form.inputs.message.label'),
                required: t('contact:form.inputs.message.required'),
                invalid: t('contact:form.inputs.message.invalid'),
                placeholder: t('contact:form.inputs.message.placeholder'),
              }}
              successText={t('contact:form.success')}
              buttonText={t('contact:form.inputs.button')}
            />
          </Box>
        </Section> */}
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    featured: file(relativePath: { eq: "spa/featured.jpg" }) {
      ...fixedImage
    }

    sauna: file(relativePath: { eq: "spa/sauna.jpg" }) {
      ...fixedImage
    }

    relaxing: file(relativePath: { eq: "spa/relaxing.jpg" }) {
      ...fixedImage
    }

    couple: file(relativePath: { eq: "spa/couple.jpg" }) {
      ...fixedImage
    }

    hotstone: file(relativePath: { eq: "spa/hotstone.jpg" }) {
      ...fixedImage
    }

    lymphatic: file(relativePath: { eq: "spa/lymphatic.jpg" }) {
      ...fixedImage
    }
  }
`

export default SpaPage
